@import '../../styles/colors';
@import '../../styles/sizes';

#Sponsors {

  .sponsors-label {
    font-weight: 600;
    text-align: center;
  }

  .sponsors-link {
    font-weight: 600;
    text-align: center;
  }

  .sponsor-button {
    height: 100%;
    border: 0;
  }

  .sponsor-button:hover {
    background-color: $dark-blue !important;

    .sponsor-button-icon {
      color: $whitesmoke !important;
    }
  }

  .sponsor-button-icon {
    color: $dark-blue !important;
  }

  .sponsor-button {
    height: 100%;
    border: 0;
  }

  .sponsor-button:hover {
    background-color: $dark-blue !important;
    .sponsor-button-icon {
      color: $whitesmoke !important;
    }
  }

  .sponsor-button-icon {
    color: $dark-blue !important;
  }

  .sponsor-card:hover {
    background-color: $dark-gray;
    box-shadow:0 14px 25px $dark-gray;
  }

  a, a:link, a:visited {
    text-decoration: none;
  }

  .sponsors-info {
    font-size: x-small;
  }

  .sponsors-image {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  .uk-slider-container {
    padding: 0 0 50px 0
  }

  .sponsors-body-image {
    overflow: hidden;
    height: 128px;
  }

  .uk-card-body {
    padding: 15px;
  }

  .uk-card-title {
    font-size: medium;
  }

  .sponsors-text {
    margin-top: 20px;
    font-size: x-small;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .line-clamp-3 {
    -webkit-line-clamp: 3;
  }

  .line-clamp-4 {
    -webkit-line-clamp: 4;
  }

  .line-clamp-5 {
    -webkit-line-clamp: 5;
  }

  .sponsors-body-top {
    height: 40px;
  }

  .sponsors-body-bottom {
    height: 40px;
    margin-top: 50px;
  }

  @media screen and (min-width: $ml) {
    .sponsors-info {
      font-size: medium;
    }

    .uk-card-title {
      font-size: large;
    }

    .sponsors-body-top {
      height: 80px;
    }

    .uk-card-body {
      padding: 20px;
    }

    .sponsors-body-bottom {
      height: 50px;
      margin-top: 50px;
    }

    .sponsors-body-image {
      overflow: hidden;
      height: 200px;
    }

    .uk-slider-container {
      padding: 0 25px 50px 25px
    }
  }

  @media screen and (min-width: $l) {
    .article-info {
      font-size: large;
    }

    .uk-card-title {
      font-size: x-large;
    }
  }
}