@use "sass:math";
@import "../styles/colors";
@import "../styles/sizes";

@media screen and (max-width: $s) {
  #sidenav {
    visibility: hidden;
  }
}

#sidenav {
  z-index: 100;
  background-color: $dark-blue;

  ul {
    min-width: 196px;
  }

  li {
    background-color: $dark-blue;
    height: $ratio;
    overflow: hidden;
    position: relative;
  }

  a {
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 2em;
  }

  a span {
    color: $medium-gray;
    font-weight: 400;
    position: relative;
    width: 100%;
    height: 100%;
    display: inline-block;
    top: math.div($ratio, 2);
    transform: translate(0, -1em);
  }

  ul li a:hover {
    background-color: $dark-gray;
  }

  li.uk-active a span {
    color: $whitesmoke;
  }
}

