@import "../styles/colors";

#Home {
	width: 100%;
	text-align: center;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	background-color: $dark-blue;

	.path {
		stroke-dasharray: 1000;
		stroke-dashoffset: 1000;
		animation: dash 5s linear forwards;
		-webkit-animation: dash 5s linear forwards;
	}

	@keyframes dash {
		to {
			stroke-dashoffset: 0;
		}
	}
	@-webkit-keyframes dash {
		to {
			stroke-dashoffset: 0;
		}
	}
}
