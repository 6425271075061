@use "sass:math";
@import "../../styles/colors";
@import "../../styles/sizes";

#Article {

  padding-top: 168px;

  .article-meta {
    font-weight: 600;
    font-size: x-large;
  }

  @media screen and (min-width: $s) {
    padding-top: 40px;
  }

  a, a:link, a:visited {
    text-decoration: none;
  }

  .news-info {
    font-size: x-small;
    font-weight: 600;
  }

  .news-image {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  .uk-slider-container {
    padding: 0 0 50px 0
  }

  .news-body-image {
    overflow: hidden;
    height: 256px;
  }

  .uk-card-body {
    padding: 15px;
  }

  .uk-card-title {
    font-size: medium;
  }

  .news-text {
    margin-top: 20px;
    font-size: small;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .line-clamp-3 {
    -webkit-line-clamp: 3;
  }

  .line-clamp-4 {
    -webkit-line-clamp: 4;
  }

  .line-clamp-5 {
    -webkit-line-clamp: 5;
  }

  .news-body-top {
    height: 40px;
  }

  .news-body-bottom {
    height: 40px;
    margin-top: 50px;
  }

  @media screen and (min-width: $ml) {
    .news-info {
      font-size: medium;
    }

    .uk-card-title {
      font-size: large;
    }

    .news-body-top {
      height: 80px;
    }

    .uk-card-body {
      padding: 20px;
    }

    .news-body-bottom {
      height: 50px;
      margin-top: 50px;
    }

    .news-body-image {
      overflow: hidden;
      height: 384px;
    }

    .uk-slider-container {
      padding: 0 25px 50px 25px
    }
  }

  @media screen and (min-width: $l) {
    .article-info {
      font-size: large;
    }
  }
}

.markdown {
  h1, h2, h3, h4, h5, p, em, code, li, span, th, tr {
    color: $dark-blue !important;
    font-weight: 400;
  }

  strong {
    font-weight: 600;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 600 !important;
  }

  h1 { font-size: 2.23125rem !important; }
  h2 { font-size: 1.7rem !important; }
  h3 { font-size: 1.5rem !important; }
  h4 { font-size: 1.25rem !important; }
  h5 { font-size: 16px !important; }
  h6 { font-size: .875rem !important; }

  @media screen and (max-width: $s) {
    padding-top: 128px !important;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    margin-bottom: 20px;
  }

  * + table {
    margin-top: 20px;
  }

  table th {
    padding: 16px 12px;
    text-align: left;
    vertical-align: bottom;
    text-transform: uppercase;
  }

  table td {
    padding: 16px 12px;
    vertical-align: top;
  }

  table td > :last-child {
    margin-bottom: 0;
  }

  table tfoot {
    font-size: 0.875rem;
  }

  table,
  table td {
    vertical-align: middle !important;
  }

  table > tr:not(:first-child),
  table > :not(:first-child) > tr,
  table > :first-child > tr:not(:first-child) {
    border-top: 1px solid $whitesmoke;
  }
}

#sidenav-article {
  z-index: 100;
  background-color: $dark-blue;

  ul {
    min-width: 196px;
  }

  @media screen and (max-width: $s) {
    ul {
      max-width: 196px;
    }
  }

  ul li a:hover {
    background-color: $dark-gray;
  }

  li.uk-active a {
    color: $whitesmoke !important;
  }

  li {
    background-color: $dark-blue;
    height: $ratio;
    overflow: hidden;
    position: relative;
  }

  a {
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;
    color: $medium-gray !important;
    font-size: 2em;
  }

  a span {
    font-family: 'Open Sans', sans-serif !important;
    font-weight: 800;
    position: relative;
    width: 100%;
    height: 100%;
    display: inline-block;
    top: math.div($ratio, 2);
    transform: translate(0, -1em);
  }
}