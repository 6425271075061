@import "../../styles/sizes";

#News {

  a, a:link, a:visited {
    text-decoration: none;
  }

  .news-info {
    font-size: x-small;
    font-weight: 600;
  }

  .news-image {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  .uk-slider-container {
    padding: 0 0 50px 0
  }

  .news-body-image {
    overflow: hidden;
    height: 128px;
  }

  .uk-card-body {
    padding: 15px;
  }

  .uk-card-title {
    font-size: medium;
  }

  .news-text {
    font-weight: 600;
    margin-top: 20px;
    font-size: small;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .line-clamp-3 {
    -webkit-line-clamp: 3;
  }

  .line-clamp-4 {
    -webkit-line-clamp: 4;
  }

  .line-clamp-5 {
    -webkit-line-clamp: 5;
  }

  .news-body-top {
    height: 40px;
  }

  .news-body-bottom {
    height: 40px;
    margin-top: 50px;
  }

  @media screen and (min-width: $ml) {
    .news-info {
      font-size: medium;
    }

    .uk-card-title {
      font-size: large;
    }

    .news-body-top {
      height: 80px;
    }

    .uk-card-body {
      padding: 20px;
    }

    .news-body-bottom {
      height: 50px;
      margin-top: 50px;
    }

    .news-body-image {
      overflow: hidden;
      height: 200px;
    }

    .uk-slider-container {
      padding: 0 25px 50px 25px
    }
  }

  @media screen and (min-width: $l) {
    .article-info {
      font-size: large;
    }
  }
}