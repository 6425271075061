@use "sass:math";

$xs: 480px;
$s: 640px;
$sm: 800px;
$m: 960px;
$ml: 1080px;
$l: 1200px;
$xl: 1600px;

$ratio: math.div(100vh, 8);