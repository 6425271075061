@import "~uikit/src/scss/variables-theme.scss";
@import "~uikit/src/scss/mixins-theme.scss";
@import "~uikit/src/scss/uikit-theme.scss";

@import "styles/colors";
@import "styles/sizes";

h1, h2, h3, h4, h5 {
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 800 !important;
  color: $whitesmoke;
}

p {
  text-align: justify;
}

p, tr, th, span, li {
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 400;
  color: $whitesmoke;
}

section > * {
  margin-left: 196px !important;
}

.section {
  background-color: $dark-blue;
  width: 100%;
}

.section-bright {
  background-color: $whitesmoke;

  h1, h2, h3, h4, h5, p {
    color: $dark-blue !important;
  }
}

.section-dark {
  background-color: $dark-blue;

  h1, h2, h3, h4, h5, p {
    color: $whitesmoke !important;
  }
}

.ken-burns-section {
  width: 100%;
  text-align: center;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.ken-burns-background {
  width: 100%;
  text-align: center;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.ken-burns-background-padding {
  padding-left: 30px !important;
}

@media screen and (max-width: $s) {
  section > * {
    padding-left: 0 !important;
    margin-left: 0 !important;
  }
}

@media screen and (max-width: $m) {
  h1, h2, h3, h4, h5, p {
    font-size: 50%;
  }
}

@media screen and (max-width: $l) {
  h1, h2, h3, h4, h5, p {
    font-size: 75%;
  }
}