@import "../../styles/sizes";

#Contacts {
  .contact-detail {
    font-size: medium;
    font-weight: 600;
    margin: 0 !important;
  }

  @media screen and (min-width: $l) {
    .contact-detail {
      font-size: large;
    }
  }

  @media screen and (min-width: $xl) {
    .contact-detail {
      font-size: large;
    }
  }
}