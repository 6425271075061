@import "../../styles/colors";
@import "../../styles/sizes";

#AboutRacing {

  #AboutRacingBackground {
    background-image: url("../../../content/images/_website/t-shirts.jpg");
  }

  .social-icon {
    box-sizing: border-box;
    width: 128px;
    height: 128px;
    margin-left: 2em;
    border-radius: 500px;
    vertical-align: middle;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    background: $black-gray;

    -webkit-transition: background .2s ease-out;
    -moz-transition: background .2s ease-out;
    -o-transition: background .2s ease-out;
    transition: background .2s ease-out;
  }

  .social-icon:focus, .social-icon:hover {
    background: $dark-blue;
    outline: 0;

    a {
      opacity: 1 !important;
    }
  }

  .uk-active > .social-icon, .social-icon:active {
    background-color: $white-gray;
  }

  a {
    color: $whitesmoke !important;
  }

  p {
    font-size: x-large;
  }

  @media screen and (max-width: $xl) {
    p {
      font-size: large;
    }
  }

  @media screen and (max-width: $m) {
    p {
      font-size: medium;
    }

    .social-icon {
      width: 96px;
      height: 96px;
      margin-left: 1em;
    }
  }

  @media screen and (max-width: $sm) {
    .social-icon {
      width: 64px;
      height: 64px;
    }
  }


  @media screen and (max-width: $xs) {
    p {
      font-size: x-small;
    }
    .social-icon {
      width: 64px;
      height: 64px;
      margin-left: 0.5em;
    }
  }
}
