@import "../../styles/sizes";

#Director {

  .director-text {
    font-size: x-large;
    font-weight: 600;
  }

  @media screen and (max-width: $xl) {
    .director-text {
      font-size: large;
    }
  }

  @media screen and (max-width: $m) {
    .director-text {
      font-size: medium;
    }
  }

  @media screen and (max-width: $xs) {
    .director-text {
      font-size: x-small;
    }
  }
}