@import "../../styles/colors";
@import "../../styles/sizes";

#RacingEvents {

	.racing-label-date {
		color: $whitesmoke !important;
	}

	.racing-label-date {
		color: $blue-gray !important;
	}

	.racing-button-panel {
		height: 33.333%;
	}

	.racing-button {
		height: 100%;
		border: 0;

		&:enabled span {
			color: $dark-blue !important;
		}

		&:disabled span {
			color: $light-gray !important;
		}
	}

	.racing-button:hover:not([disabled]) {
		background-color: $dark-blue !important;
		.racing-button-icon {
			color: $whitesmoke !important;
		}
	}

	.racing-label-date {
		font-size: 1.5em;
	}

	.event-number {
		font-size: 5em;
		font-weight: 800;
	}

	.event-detail {
		font-size: medium;
		font-weight: 600;
		text-align: center;
	}

	.event-body {
		padding: 20px;
		height: 50px;
	}

	@media screen and (min-width: $l) {
		.event-number {
			font-size: 8em;
		}
		.event-body {
			height: 80px;
		}
		.event-detail {
			font-size: large;
		}
	}

	@media screen and (min-width: $xl) {
		.event-number {
			font-size: 10em;
		}
		.event-detail {
			font-size: large;
		}
	}
}